<template>
    <div>
        <div class="card">
            <div class="row mx-2">
                <div class="col">
                    <h3>Configuración Bitácora {{ item.nombre }}</h3>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="row mx-2 mt-2">
                <div class="col">
                    <v-btn @click="saveActiveParameters" rounded color="primary" dark>
                        Guardar
                    </v-btn>
                </div>

            </div>
            <div class="row">
                <div class="col">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        id
                                    </th>
                                    <th class="text-center">
                                        Parametro
                                    </th>
                                    <th class="text-center">
                                        Activo
                                    </th>
                                    <th class="text-center">
                                        Configurar Opciones
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="p in parameters" :key="p.id">
                                    <td class="text-center">{{ p.id }}</td>
                                    <td class="text-center">{{ p.nombre }}</td>
                                    <td class="text-center">
                                        <input type="checkbox" name="" id="" v-model="p.activo">
                                    </td>
                                    <td class="text-center">
                                        <i class="material-icons" style="cursor:pointer;color:gray;"
                                            @click="setupParameter(p)">settings</i>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </div>

        <div class="card py-2" v-show="Object.keys(current).length > 0">
            <div class="row mx-1">
                <div class="col">
                    <h4><b>Configuración de Parámetro : </b>{{ `${current.nombre}` }}</h4>
                </div>
            </div>
            <div class="row mx-1">
                <div class="col">
                    <v-text-field label="Agregar Nueva Opción" v-model="new_option" dense></v-text-field>
                </div>
                <div class="col">
                    <v-btn color="cyan" elevation="3" small @click="createOption">Agregar</v-btn>
                </div>
            </div>
            <div class="row mx-1">
                <div class="col">
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        id
                                    </th>
                                    <th class="text-center">
                                        Nombre Opción
                                    </th>
                                    <th class="text-center">
                                        Activo
                                    </th>
                                    <th class="text-center">
                                        Guardar
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="o in options" :key="o.id">
                                    <td class="text-center">{{ o.id }}</td>
                                    <td class="text-center">{{ o.nombre }}</td>
                                    <td class="text-center">
                                        <input type="checkbox" name="" id="" v-model="o.activo">
                                    </td>
                                    <td class="text-center">
                                        <v-btn color="cyan" elevation="3" small @click="saveOption(o)">guardar</v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        parameters: [],
        options: [],
        item: { id: null },
        current: {},
        new_option: null
    }),
    async mounted() {
        //console.log('id',this.$route.params.id)
        this.item.id = this.$route.params.id;
        await this.loadParameters();
        await this.loadItem();
    },
    methods: {
        async loadParameters() {
            let url = `${this.base_url}parametros`
            await this.axios.get(url, this.headers).then(r => {
                this.parameters = r.data
            })
        },
        async loadItem() {
            let url = `${this.base_url}informacion_general/${this.item.id}`;
            await this.axios.get(url, this.headers).then(r => {
                this.item = r.data
                this.parameters.forEach((p,idx) => {
                    let find = this.item.parametros_ids.find(id => p.id == id)
                    if(find){
                        this.$set(this.parameters[idx],'activo',1)
                    }else{
                        this.$set(this.parameters[idx],'activo',0)
                    }
                });
            })
        },
        async setupParameter(parameter) {
            this.current = parameter
            let url = `${this.base_url}cargar_opciones/${parameter.id}/tipo_bitacora/${this.item.id}`
            await this.axios.get(url, this.headers).then(r => {
                this.options = r.data
            })
        },
        async createOption() {
            let url = `${this.base_url}parametros_opciones`
            let body = {
                nombre: this.new_option,
                tipos_bitacoras_id: this.item.id,
                parametros_id: this.current.id
            }
            await this.axios.post(url, body, this.headers).then(r => {
                console.log(r)
                this.setupParameter(this.current)
                this.clearNew()
            })
        },
        async saveOption(option) {
            let url = `${this.base_url}parametros_opciones/${option.id}`
            let body = {
                nombre: option.nombre,
                tipos_bitacoras_id: option.tipos_bitacoras_id,
                parametros_id: option.parametros_id,
                activo:option.activo
            }
            await this.axios.put(url, body, this.headers).then(r => {
                console.log(r)
                this.setupParameter(this.current)
                this.clearNew()
            })
        },
        async saveActiveParameters() {
            let url = `${this.base_url}tipos_bitacoras/bitacora/activar_parametros`
            let ids = this.parameters.filter(p => p.activo == 1).map(p => p.id)
            let body = {
                ids:ids,
                tipos_bitacoras_id:this.item.id
            }
            await this.axios.post(url, body, this.headers).then(r => {
                console.log(r)
            })
        },
        clearNew() {
            this.new_option = null
        }
    },
    computed: {
        ...mapState(['base_url', 'headers'])
    }
}
</script>